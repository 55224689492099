<template>
    <div>
        <div class="page-wrapper">
        <div class="topbar-one">
            <div class="container">
                <div class="topbar-one__left">
                    <a href="#">zhyldyzacademy@protonmail.com</a>
                    <a href="#">+996 555 94 95 01</a>
                </div><!-- /.topbar-one__left -->
                <div class="topbar-one__right">
                    <a href="#">Login</a>
                    <a href="#">Register</a>
                </div><!-- /.topbar-one__right -->
            </div><!-- /.container -->
        </div><!-- /.topbar-one -->
        <header class="site-header site-header__header-one site-header__inner-page ">
            <nav class="navbar navbar-expand-lg navbar-light header-navigation stricky">
                <div class="container clearfix">
                    <!-- Brand and toggle get grouped for better mobile display -->
                    <div class="logo-box clearfix">
                        <a class="navbar-brand" href="index.html">
                            <img :src="require('../../assets/logo_1.png')" class="main-logo" width="128" alt="Awesome Image" />
                        </a>
                        <div class="header__social">
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="https://www.facebook.com/zhyldyz.academy" target="_blank"><i class="fab fa-facebook-square"></i></a>
                            <a href="https://api.whatsapp.com/send/?phone=%2B996704949501&text&app_absent=0" target="_blank"><i class="fab fa-whatsapp"></i></a>
                            <a href="https://www.instagram.com/zhyldyz_academy/" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div><!-- /.header__social -->
                        <button class="menu-toggler" data-target=".main-navigation">
                            <span class="kipso-icon-menu"></span>
                        </button>
                    </div><!-- /.logo-box -->
                    <!-- Collect the nav links, forms, and other content for toggling -->
                    <div class="main-navigation">
                        <ul class=" navigation-box">
                            <li >
                                <a @click="goTo('Home')" style="font-weight: 700;">Home</a>
                               <!--<ul class="sub-menu">
                                    <li><a href="index.html">Home 01</a></li>
                                    <li><a href="index-2.html">Home 02</a></li>
                                    <li><a href="index-3.html">Home 03</a></li>
                                    <li><a href="#">Header Versions</a>
                                        <ul class="sub-menu">
                                            <li><a href="index.html">Header 01</a></li>
                                            <li><a href="index-2.html">Header 02</a></li>
                                            <li><a href="index-3.html">Header 03</a></li>
                                        </ul>
                                    </li>
                                </ul> /.sub-menu -->
                            </li>
                            <!--<li>
                                <a href="#">Pages</a>
                                <ul class="sub-menu">
                                    <li><a href="about.html">About Page</a></li>
                                    <li><a href="gallery.html">Gallery</a></li>
                                    <li><a href="pricing.html">Pricing Plans</a></li>
                                    <li><a href="faq.html">FAQ'S</a></li>
                                </ul> 
                            </li>/.sub-menu -->
                            <li class="current" >
                                <a @click="goTo('Courses')" style="font-weight: 700;">Courses</a>
                                <!--<ul class="sub-menu">
                                    <li><a href="courses.html">Courses</a></li>
                                    <li><a href="course-details.html">Course Details</a></li>
                                </ul> /.sub-menu -->
                            </li>
                            <li >
                                <a @click="goTo('Teachers')" style="font-weight: 700;">Teachers</a>
                                <!--<ul class="sub-menu">
                                    <li><a href="teachers.html">Teachers</a></li>
                                    <li><a href="team-details.html">Teachers Details</a></li>
                                    <li><a href="become-teacher.html">Become Teacher</a></li>
                                </ul> /.sub-menu -->
                            </li>
                            <li >
                                <a @click="goTo('News')" style="font-weight: 700;">News</a>
                                <!-- <ul class="sub-menu">
                                    <li><a href="news.html">News Page</a></li>
                                    <li><a href="news-details.html">News Details</a></li>
                                </ul>/.sub-menu -->
                            </li>
                            <li >
                                <a @click="goTo('About')" style="font-weight: 700;">About</a>
                            </li>
                        </ul>
                    </div><!-- /.navbar-collapse -->
                    <div class="right-side-box">
                        <a class="header__search-btn search-popup__toggler" href="#"><i class="kipso-icon-magnifying-glass"></i>
                            <!-- /.kipso-icon-magnifying-glass --></a>
                    </div><!-- /.right-side-box -->
                </div>
                <!-- /.container -->
            </nav>
            <div class="site-header__decor">
                <div class="site-header__decor-row">
                    <div class="site-header__decor-single">
                        <div class="site-header__decor-inner-1"></div><!-- /.site-header__decor-inner -->
                    </div><!-- /.site-header__decor-single -->
                    <div class="site-header__decor-single">
                        <div class="site-header__decor-inner-2"></div><!-- /.site-header__decor-inner -->
                    </div><!-- /.site-header__decor-single -->
                    <div class="site-header__decor-single">
                        <div class="site-header__decor-inner-3"></div><!-- /.site-header__decor-inner -->
                    </div><!-- /.site-header__decor-single -->
                </div><!-- /.site-header__decor-row -->
            </div><!-- /.site-header__decor -->
        </header><!-- /.site-header -->
        <section class="inner-banner">
            <div class="container">
                <ul class="list-unstyled thm-breadcrumb">
                    <li><a href="#">Home</a></li>
                    <li class="active"><a href="#">Courses</a></li>
                </ul><!-- /.list-unstyled -->
                <h2 class="inner-banner__title">Courses</h2><!-- /.inner-banner__title -->
            </div><!-- /.container -->
        </section><!-- /.inner-banner -->
        <section class="course-one course-page">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="course-one__single">
                            <div class="course-one__image">
                                <img :src="require('../../assets/images/course-1-1.jpeg')" alt="">
                                <i class="far fa-heart"></i><!-- /.far fa-heart -->
                            </div><!-- /.course-one__image -->
                            <div class="course-one__content">
                                <a href="#" class="course-one__category" style="color: #fff;">Beginner</a><!-- /.course-one__category -->
                                <div class="course-one__admin">
                                    <img :src="require('../../assets/images/team-1-1.jpeg')" alt="">
                                    by <a href="teacher-details.html">Lou Guerrero</a>
                                </div><!-- /.course-one__admin -->
                                <h2 class="course-one__title"><a href="course-details.html">New react bootcamp</a></h2>
                                <!-- /.course-one__title -->
                                <div class="course-one__stars">
                                    <span class="course-one__stars-wrap">
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </span><!-- /.course-one__stars-wrap -->
                                    <span class="course-one__count">4.8</span><!-- /.course-one__count -->
                                    <span class="course-one__stars-count">250</span><!-- /.course-one__stars-count -->
                                </div><!-- /.course-one__stars -->
                                <div class="course-one__meta">
                                    <a href="course-details.html"><i class="far fa-clock"></i> 10 Hours</a>
                                    <a href="course-details.html"><i class="far fa-folder-open"></i> 6 Lectures</a>
                                    <a href="course-details.html">$18</a>
                                </div><!-- /.course-one__meta -->
                                <a href="#" class="course-one__link">See Preview</a><!-- /.course-one__link -->
                            </div><!-- /.course-one__content -->
                        </div><!-- /.course-one__single -->
                    </div><!-- /.col-lg-4 -->
                    <div class="col-lg-4">
                        <div class="course-one__single">
                            <div class="course-one__image">
                                <img :src="require('../../assets/images/course-1-2.jpeg')" alt="">
                                <i class="far fa-heart"></i><!-- /.far fa-heart -->
                            </div><!-- /.course-one__image -->
                            <div class="course-one__content">
                                <a href="#" class="course-one__category" style="color: #fff;">Elementary</a><!-- /.course-one__category -->
                                <div class="course-one__admin">
                                    <img :src="require('../../assets/images/team-1-2.jpeg')" alt="">
                                    by <a href="teacher-details.html">Cora Diaz</a>
                                </div><!-- /.course-one__admin -->
                                <h2 class="course-one__title"><a href="course-details.html">Improve editing skills</a></h2>
                                <!-- /.course-one__title -->
                                <div class="course-one__stars">
                                    <span class="course-one__stars-wrap">
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </span><!-- /.course-one__stars-wrap -->
                                    <span class="course-one__count">4.8</span><!-- /.course-one__count -->
                                    <span class="course-one__stars-count">250</span><!-- /.course-one__stars-count -->
                                </div><!-- /.course-one__stars -->
                                <div class="course-one__meta">
                                    <a href="course-details.html"><i class="far fa-clock"></i> 10 Hours</a>
                                    <a href="course-details.html"><i class="far fa-folder-open"></i> 6 Lectures</a>
                                    <a href="course-details.html">$18</a>
                                </div><!-- /.course-one__meta -->
                                <a href="#" class="course-one__link">See Preview</a><!-- /.course-one__link -->
                            </div><!-- /.course-one__content -->
                        </div><!-- /.course-one__single -->
                    </div><!-- /.col-lg-4 -->
                    <div class="col-lg-4">
                        <div class="course-one__single">
                            <div class="course-one__image">
                                <img :src="require('../../assets/images/course-1-3.jpeg')" alt="">
                                <i class="far fa-heart"></i><!-- /.far fa-heart -->
                            </div><!-- /.course-one__image -->
                            <div class="course-one__content">
                                <a href="#" class="course-one__category" style="color: #fff;">Pre-Intermediate</a><!-- /.course-one__category -->
                                <div class="course-one__admin">
                                    <img :src="require('../../assets/images/team-1-3.jpeg')" alt="">
                                    by <a href="teacher-details.html">Ruth Becker</a>
                                </div><!-- /.course-one__admin -->
                                <h2 class="course-one__title"><a href="course-details.html">Marketing strategies</a></h2>
                                <!-- /.course-one__title -->
                                <div class="course-one__stars">
                                    <span class="course-one__stars-wrap">
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </span><!-- /.course-one__stars-wrap -->
                                    <span class="course-one__count">4.8</span><!-- /.course-one__count -->
                                    <span class="course-one__stars-count">250</span><!-- /.course-one__stars-count -->
                                </div><!-- /.course-one__stars -->
                                <div class="course-one__meta">
                                    <a href="course-details.html"><i class="far fa-clock"></i> 10 Hours</a>
                                    <a href="course-details.html"><i class="far fa-folder-open"></i> 6 Lectures</a>
                                    <a href="course-details.html">$18</a>
                                </div><!-- /.course-one__meta -->
                                <a href="#" class="course-one__link">See Preview</a><!-- /.course-one__link -->
                            </div><!-- /.course-one__content -->
                        </div><!-- /.course-one__single -->
                    </div><!-- /.col-lg-4 -->
                    <div class="col-lg-4">
                        <div class="course-one__single">
                            <div class="course-one__image">
                                <img :src="require('../../assets/images/course-1-4.jpeg')" alt="">
                                <i class="far fa-heart"></i><!-- /.far fa-heart -->
                            </div><!-- /.course-one__image -->
                            <div class="course-one__content">
                                <a href="#" class="course-one__category" style="color: #fff;">ОРТ Русский язык</a><!-- /.course-one__category -->
                                <div class="course-one__admin">
                                    <img :src="require('../../assets/images/team-1-4.jpeg')" alt="">
                                    by <a href="teacher-details.html">Ernest Rodriquez</a>
                                </div><!-- /.course-one__admin -->
                                <h2 class="course-one__title"><a href="course-details.html">Basics of photography</a></h2>
                                <!-- /.course-one__title -->
                                <div class="course-one__stars">
                                    <span class="course-one__stars-wrap">
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </span><!-- /.course-one__stars-wrap -->
                                    <span class="course-one__count">4.8</span><!-- /.course-one__count -->
                                    <span class="course-one__stars-count">250</span><!-- /.course-one__stars-count -->
                                </div><!-- /.course-one__stars -->
                                <div class="course-one__meta">
                                    <a href="course-details.html"><i class="far fa-clock"></i> 10 Hours</a>
                                    <a href="course-details.html"><i class="far fa-folder-open"></i> 6 Lectures</a>
                                    <a href="course-details.html">$18</a>
                                </div><!-- /.course-one__meta -->
                                <a href="#" class="course-one__link">See Preview</a><!-- /.course-one__link -->
                            </div><!-- /.course-one__content -->
                        </div><!-- /.course-one__single -->
                    </div><!-- /.col-lg-4 -->
                    <div class="col-lg-4">
                        <div class="course-one__single">
                            <div class="course-one__image">
                                <img :src="require('../../assets/images/course-1-5.jpeg')" alt="">
                                <i class="far fa-heart"></i><!-- /.far fa-heart -->
                            </div><!-- /.course-one__image -->
                            <div class="course-one__content">
                                <a href="#" class="course-one__category" style="color: #fff;">ОРТ Кыргызский язык</a><!-- /.course-one__category -->
                                <div class="course-one__admin">
                                    <img :src="require('../../assets/images/team-1-5.jpeg')" alt="">
                                    by <a href="teacher-details.html">Isabella Stanley</a>
                                </div><!-- /.course-one__admin -->
                                <h2 class="course-one__title"><a href="course-details.html">Affiliate bootcamp</a>
                                </h2>
                                <!-- /.course-one__title -->
                                <div class="course-one__stars">
                                    <span class="course-one__stars-wrap">
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </span><!-- /.course-one__stars-wrap -->
                                    <span class="course-one__count">4.8</span><!-- /.course-one__count -->
                                    <span class="course-one__stars-count">250</span><!-- /.course-one__stars-count -->
                                </div><!-- /.course-one__stars -->
                                <div class="course-one__meta">
                                    <a href="course-details.html"><i class="far fa-clock"></i> 10 Hours</a>
                                    <a href="course-details.html"><i class="far fa-folder-open"></i> 6 Lectures</a>
                                    <a href="course-details.html">$18</a>
                                </div><!-- /.course-one__meta -->
                                <a href="#" class="course-one__link">See Preview</a><!-- /.course-one__link -->
                            </div><!-- /.course-one__content -->
                        </div><!-- /.course-one__single -->
                    </div><!-- /.col-lg-4 -->
                    <div class="col-lg-4">
                        <div class="course-one__single">
                            <div class="course-one__image">
                                <img :src="require('../../assets/images/course-1-6.jpeg')" alt="">
                                <i class="far fa-heart"></i><!-- /.far fa-heart -->
                            </div><!-- /.course-one__image -->
                            <div class="course-one__content">
                                <a href="#" class="course-one__category" style="color: #fff;">ОРТ Математика</a><!-- /.course-one__category -->
                                <div class="course-one__admin">
                                    <img :src="require('../../assets/images/team-1-6.jpeg')" alt="">
                                    by <a href="teacher-details.html">Katherine Collins</a>
                                </div><!-- /.course-one__admin -->
                                <h2 class="course-one__title"><a href="course-details.html">Healthy workout tips </a></h2>
                                <!-- /.course-one__title -->
                                <div class="course-one__stars">
                                    <span class="course-one__stars-wrap">
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </span><!-- /.course-one__stars-wrap -->
                                    <span class="course-one__count">4.8</span><!-- /.course-one__count -->
                                    <span class="course-one__stars-count">250</span><!-- /.course-one__stars-count -->
                                </div><!-- /.course-one__stars -->
                                <div class="course-one__meta">
                                    <a href="course-details.html"><i class="far fa-clock"></i> 10 Hours</a>
                                    <a href="course-details.html"><i class="far fa-folder-open"></i> 6 Lectures</a>
                                    <a href="course-details.html">$18</a>
                                </div><!-- /.course-one__meta -->
                                <a href="#" class="course-one__link">See Preview</a><!-- /.course-one__link -->
                            </div><!-- /.course-one__content -->
                        </div><!-- /.course-one__single -->
                    </div><!-- /.col-lg-4 -->
                </div><!-- /.row -->
                <div class="post-pagination">
                    <a href="#"><i class="fa fa-angle-double-left"></i><!-- /.fa fa-angle-double-left --></a>
                    <a class="active" href="#">1</a>
                    <a href="#">2</a>
                    <a href="#">3</a>
                    <a href="#">4</a>
                    <a href="#"><i class="fa fa-angle-double-right"></i><!-- /.fa fa-angle-double-left --></a>
                </div><!-- /.post-pagination -->

            </div><!-- /.container -->
        </section><!-- /.course-one course-page -->
        <Footer></Footer>

    </div><!-- /.page-wrapper -->

    </div>
</template>
<script>
import Footer from "../../components/footer/Footer.vue";
import '../../assets/css/style.css'
export default {
    name: "Courses",
    components: {
        Footer
    },
     methods :{
      goTo (url) {
         this.link = url;
         switch (url) {
            case 'Courses':
              this.$router.push({ name: 'Courses' });
               break;
            case 'Teachers':
               this.$router.push({ name: 'Teachers' });
               break;
            case 'News':
               this.$router.push({ name: 'News' });
               break;
            case 'About':
               this.$router.push({ name: 'About' });
               break;
            case 'Home':
               this.$router.push({ name: 'Main' });
               break;
         }
      },
    }
}
</script>